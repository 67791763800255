var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _vm._v(" Energie Bewirtschaftung neu Denken... "),
      _c("v-img", { attrs: { src: _vm.Image, width: "100vw" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }