<template>
    <v-card flat>
        Energie Bewirtschaftung neu Denken...
        <v-img :src="Image"  width="100vw" ></v-img>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            Image: require("@/assets/H8A4789_Geschaeftskundenberatung_NEW_Energie_guenstiger_Tarif.webp")
        }
    },
    methods: {},
    computed: {},
    mounted() {
        // SignIn Check
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login")
            return
        }
    }
}
</script>

<style>

</style>